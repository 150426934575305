<!--  -->
<template>
  <div class='video-view'>
    <div class="nav">
      <div class="title">《{{info.keshi_name}}》</div>
      <div>
        <span>播放倍速：</span>
        <el-select v-model="times" placeholder="请选择" @change="onChange">
          <el-option label="1.0" value="1.0"></el-option>
          <el-option label="1.25" value="1.25"></el-option>
          <el-option label="1.5" value="1.5"></el-option>
        </el-select>
      </div>
    </div>
    <div class="myVideo">
      <video id="myVideo" autoplay controls controlslist="noplaybackrate nodownload noremoteplayback disablePictureInPicture" disablePictureInPicture v-if="info.keshi_url" oncontextmenu="return false;" ondragstart="return false;">
        <source :src="info.keshi_url" type="video/mp4" />
        <source src="movie.ogg" type="video/ogg" /> 对不起，您的浏览器暂不支持video视频标签!
      </video>
    </div>
    <div class="quit" @click="handleQuit">返回上一页面</div>

    <video-view v-model="zhuapai" type="spzp" :id="$route.query.id" :name="info.keshi_name" @hide="hideChange"></video-view>
  </div>
</template>

<script>
import VideoView from './components/video/index.vue'
import Api from '../api/study'

export default {
  components: {
    VideoView
  },
  data() {
    return {
      info: {},
      currentTime: '',
      planTime: '',
      times: '1.0',
      zhuapai: false,
      status: true,
      stopTime: [],
      onEnded: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getVideo()
    document.onkeydown = document.onkeyup = document.onkeypress = function(event) { 
      var e = event || window.event || arguments.callee.caller.arguments[0]; 
      if (e && e.keyCode == 123) { 
        e.returnValue = false; 
        return (false); 
      } 
    }
    document.oncontextmenu = new Function("return false;");
  },
  mounted() {
    
  },
  beforeDestroy () {
    document.onkeydown = document.onkeyup = document.onkeypress = () => {}
    document.oncontextmenu = () => {}
    if (this.info.finish == '1') {
      return
    }
    this.coursejilu(1)
    setTimeout(() => {
      window.οnbefοreunlοad = () => {}
    }, 500)
    clearInterval(this.planTime)
  },
  //方法集合
  methods: {
    getVideo () {
      Api.getVideo({
        vid: this.$route.query.id
      }).then(res => {
        if (res.data.state == 0) {
          this.$message.warning('不可同时播放多个视频!')
          this.$router.back()
          return;
        }
        // res.data.zhuapai_time = [res.data.study_time+10, ...res.data.zhuapai_time]
        this.info = res.data
        // this.stopTime = [res.data.study_time+5, (Math.random()*(res.data.keshi_shichang-10-3000) + 3000).toFixed(0)]
        this.stopTime = []
        if (this.info.keshi_shichang > 2400) {
          this.stopTime = [(Math.random()*(1800-1200) + 1200).toFixed(0), (Math.random()*(res.data.keshi_shichang-10-3000) + 3000).toFixed(0)]
        } else {
          this.stopTime = [(Math.random()*(1800-1200) + 1200).toFixed(0)]
        }
        if (this.info.finish == '1') {
          return;
        }
        this.currentTime = res.data.study_time
        this.$nextTick(() => {
          setTimeout(() => {
            let video = document.getElementById("myVideo")
            video.currentTime = this.currentTime
            video.play()
            video.addEventListener("pause", () => {
              this.coursejilu()
            })
            video.addEventListener('ratechange', function () {
              if (video.playbackRate > 1.5) {
                video.playbackRate = 1.5
              }
            }, false)
            window.addEventListener("beforeunload", (event) => {
              event.returnValue = '';
							this.coursejilu(1)
						}, false);
            if (this.info.zhuapai_time.indexOf(this.currentTime) != -1) {
              // video.pause()
              // clearInterval(this.planTime)
              // this.status = false
              // this.zhuapai = true
              this.studyzp()
              return;
            }
            this.playPlan()
            this.playOver()
            this.planTime = setInterval(() => {
              this.savePlayPlan()
            }, 60000)
          }, 200)
        })
      })
    },
    onChange () {
      document.getElementById("myVideo").playbackRate = this.times
    },
    // 获取视频播放进度
    playPlan () {
      if (this.info.finish == '1') {
        return
      }
      var video = document.getElementById("myVideo");
      video.addEventListener("timeupdate", () => {
        if (!this.status) {
          // video.pause()
          // clearInterval(this.planTime)
          // this.status = false
          // this.zhuapai = true
          this.studyzp()
        }
        let currentTime = Math.floor(video.currentTime);
        if (currentTime - this.currentTime > 5) {
          video.pause()
          video.currentTime = this.currentTime
        } else if (currentTime - this.currentTime == 1) {
          this.currentTime = currentTime
          this.setStop()
          if (this.info.zhuapai_time.indexOf(currentTime) != -1) {
            // video.pause()
            // clearInterval(this.planTime)
            // this.status = false
            // this.zhuapai = true
            this.studyzp()
          }
        }
      },false)
    },
    setStop () {
      if (this.stopTime.indexOf(String(this.currentTime)) != -1) {
        let video = video = document.getElementById("myVideo");
        video.pause()
        clearInterval(this.planTime)
        this.$confirm('继续学习请按确定！', '温馨提示', {
          confirmButtonText: '确定',
          showCancelButton: false,
          showClose: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type: 'info',
          customClass: 'customClass',
          iconClass: 'none',
          center: true
        }).then(() => {
          video.play()
          this.playPlan()
          this.playOver()
          this.savePlayPlan()
          let planTime = setInterval(() => {
            this.savePlayPlan()
          }, 60000)
          this.planTime = planTime
        }).catch(() => {})
      }
    },
    // 视频播放结束
    playOver () {
      if (this.info.finish == '1') {
        return
      }
      var video = document.getElementById("myVideo");
      video.addEventListener("ended", () => {
        let keshi_shichang = Number(this.info.keshi_shichang )
        if (keshi_shichang - this.currentTime > 5) {
          return;
        }
        let currentTime = video.currentTime
        this.currentTime = currentTime
        Api.playOver({
          vid: this.$route.query.id,
          study_time: currentTime
        }).then(res => {
          let userInfo = localStorage.getItem('userInfo')
          userInfo = JSON.parse(userInfo)
          if (this.onEnded || !res.data.msg) {
            return;
          }
          this.onEnded = true
          if (res.data.code == 1) {
            if (userInfo.age >= 50) {
              this.$confirm('祝贺您已完成专业课程的学习（50岁免考），学完即可查询学分，进学习平台或“广东省执业药师管理系统”查询学分。', '温馨提示', {
                confirmButtonText: '确定',
                showCancelButton: false,
                type: 'info',
                center: true
              }).then(() => {
                this.$router.back()
              }).catch(() => {})
            } else {
              this.$confirm('本节课已学完,将跳转至课程列表选择其他课程', '温馨提示', {
                confirmButtonText: '确定',
                showCancelButton: false,
                type: 'info',
                center: true
              }).then(() => {
                this.savePlayPlan()
                this.getExamRoot()
              }).catch(()=>{})
            }
          } else{
            this.$confirm('本节课已学完,将跳转至课程列表选择其他课程', '温馨提示', {
              confirmButtonText: '确定',
              showCancelButton: false,
              type: 'info',
              center: true
            }).then(() => {
              if (userInfo.age >= 50) {
                this.$router.back()
              } else {
                this.savePlayPlan()
                this.getExamRoot()
              }
            }).catch(()=>{})
          }
        })
      })
    },
    // 保存播放进度
    savePlayPlan () {
      Api.savePlayPlan({
        id: this.$route.query.id,
        jilu_id: this.info.jilu_id,
        study_time: this.currentTime
      }).then(res => {
        if (res.data.state == 0) {
          this.$message.warning('不可同时播放多个视频!')
          this.$router.back()
          return;
        }
      })
    },
    // 保存播放进度
    coursejilu (exit) {
      Api.coursejilu({
        jilu_id: this.info.jilu_id,
        currentTime: this.currentTime,
        exit: exit || ''
      })
    },
    getExamRoot () {
      Api.getExamRoot({
        vid: this.$route.query.id
      }).then(res => {
        if (res.data.code == 1) {
          this.$router.replace({name: 'Exam', query: {id: res.data.exam_id}})
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    hideChange () {
      this.status = true
      let video = document.getElementById("myVideo")
      video.play()
      this.playPlan()
      this.playOver()
      this.savePlayPlan()
      let planTime = setInterval(() => {
        this.savePlayPlan()
      }, 60000)
      this.planTime = planTime
    },
    handleQuit () {
      this.$router.back()
    },
    // 视频学习抓拍
    studyzp () {
      Api.studyzp({
        file: '',
        keshi_name: this.info.keshi_name,
        vid: this.$route.query.id,
        zhuapai_time: this.currentTime
      })
    }
  },
}
</script>

<style lang='less' scoped>
.video-view {
  width: 70%;
  height: 70%;
  margin: 0 auto;
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .title {
      span {
        color: @default;
      }
    }
  }
  .myVideo {
    margin-top: 10px;
    video {
      width: 100%;
      height: 100%;
    }
  }

  .quit {
    text-align: right;
    color: #409EFF;
    margin-top: 10px;
    font-size: 20px;
    padding-bottom: 5px;
    position: relative;
    cursor: pointer;
    &::after {
      content: '';
      width: 123px;
      height: 1px;
      background: #409EFF;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
</style>

<style lang="less">
.customClass {
  color: red;
  .el-message-box__title {
    color: red;
  }
  .el-message-box__container p {
    color: #000;
  }
}
</style>